/** @jsx jsx */
import { jsx, css } from "@emotion/core"

const Row = ({ children }) => (
  <div
    css={theme => css`
      display: flex;
      flex-wrap: wrap;
      margin: ${theme.styles.grid.default.gutter / -2}px;
    `}
  >
    {children}
  </div>
)

export default Row
