/** @jsx jsx */
import { jsx, css } from "@emotion/core"

const roundNumber = value => Math.floor(value * 100 * 100) / 100

const dynamicSpan = ({ theme, span = {} }) => {
  // span rules
  const breakpointsInUse = Object.keys(span)
  const firstBreakpointAlias = theme.breakpointAlias[0]
  const generateRule = v => `width: ${v}%;`
  let rules = ""
  if (!breakpointsInUse.includes(firstBreakpointAlias)) {
    // default rule for smallest size
    rules += generateRule(100)
  }
  breakpointsInUse.forEach(k => {
    const v = span[k]
    if (k === firstBreakpointAlias) {
      rules += generateRule(roundNumber(v))
    } else {
      rules += theme.helpers.mediaquery(k, generateRule(roundNumber(v)))
    }
  })
  return rules
}

const dynamicOffset = ({ theme, offset = {} }) => {
  // offset rules
  const breakpointsInUse = Object.keys(offset)
  const firstBreakpointAlias = theme.breakpointAlias[0]
  const generateRule = v => `margin-left: ${v}%;`
  let rules = ""
  breakpointsInUse.forEach(k => {
    const v = offset[k]
    if (k === firstBreakpointAlias) {
      rules += generateRule(roundNumber(v))
    } else {
      rules += theme.helpers.mediaquery(k, generateRule(roundNumber(v)))
    }
  })

  return rules
}

const Cell = ({ children, span, offset, ...props }) => (
  <div
    css={theme =>
      css`
        flex-grow: unset;
        box-sizing: border-box;
        padding: ${theme.styles.grid.default.gutter / 2}px;
        ${dynamicSpan({ theme, span })}
        ${dynamicOffset({ theme, offset })}
      `
    }
    {...props}
  >
    {children}
  </div>
)

export default Cell
